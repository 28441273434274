import { IStorageService, IUserService } from "services/models";
import { MyJWTToken, TMUser } from "./models";
import { TGeneralFn } from "models";
import { jwtDecode } from "jwt-decode";

export class UserService implements IUserService {
  private _userData: TMUser | null = null;

  private storageService: IStorageService;
  private userLoginListener = new Set<TGeneralFn>();

  constructor(storageService: IStorageService) {
    this.storageService = storageService;
  }

  public initialize(data: MyJWTToken, token:string) {
    this.writeIdToStorage(data.id);
    this.writeTokenToStorage(token);
  }
  public setUserData(data: TMUser) {
    this._userData = data;
  }

  public initializeLoginListener(fn: TGeneralFn) {
    this.userLoginListener.add(fn);

    const payload = {
      id: this.getUserId,
      token: this.getUserToken,
    };

    this.userLoginListener.forEach((fn) => fn(payload));
    return () => this.userLoginListener.delete(fn);
  }

  public get getUserData() {
    return this._userData as TMUser;
  }

  public writeIdToStorage(userId: number) {
    this.storageService.set("user_id", `${userId}`);
  }

  public writeTokenToStorage(token: string) {
    this.storageService.set("auth_token", token);
  }

  public saveUserLoginInfo({ id, token }) {
    this.userLoginListener.forEach((fn) => fn({ id, token }));
  }

  public get getUserId() {
    return this.storageService.get("user_id");
  }

  public get getUserToken() {
    return this.storageService.get("auth_token");
  }
  public get getUserJWT() {
    const code = this.getUserToken ? jwtDecode(this.getUserToken as string) : null;
    return code as MyJWTToken;
  }

  public destroy() {
    this.storageService.remove("user_id");
    this.storageService.remove("auth_token");
    this._userData = null;
    this.userLoginListener.forEach((fn) => fn({ id: null, token: null }));
  }
}
