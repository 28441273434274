import { TRoute } from "services/menuService/models";
import {
  IMenuService,
  IPermissionService,
  IRouteService,
  IStorageService,
} from "services/models";

export class RouteService implements IRouteService {
  private readonly permissionService: IPermissionService;
  private readonly storageService: IStorageService;
  private readonly menuService: IMenuService;

  constructor(
    permissionService: IPermissionService,
    storageService: IStorageService,
    menuService: IMenuService
  ) {
    this.permissionService = permissionService;
    this.storageService = storageService;
    this.menuService = menuService;
  }

  public get getFirstNavigatedPage() {
    const menuList = this.menuService.getMenuList;

    for (let i = 0; i < menuList.length; i++) {
      if (menuList[i].allowed) {
        if (!menuList[i].children) return `${menuList[i].url}`;

        const submenu = menuList?.[i]?.children as TRoute[];
        for (let j = 0; j < submenu.length; j++) {
          return `${submenu?.[j].url}`;
        }
      }
    }
    return "/dashboard";
  }

  public get getLandingPage() {
    const lastLocation = this.getLastLocation;

    return lastLocation ? lastLocation : this.getFirstNavigatedPage;
  }

  public saveLastLocation(pathName: string) {
    this.storageService.set("last_location", pathName);
  }

  public get getLastLocation() {
    return this.storageService.get("last_location");
  }

  public hasAccessToRoutes(pathname: string) {
    const page = this.permissionService.getUrlPermissionLists.find((page) => {
      return pathname.match(page.url);
    });

    return page?.module
      ? this.permissionService.permissionGuaranteed(page?.module)
      : false;
  }
}
