import { Button, Center, HStack, Text, VStack } from "@chakra-ui/react";
import { UnauthorizeIcon } from "icons/UnauthorizeIcon";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useServices } from "services/hooks";

export const PermissionError: React.FC = () => {
  const { permissionService, routeService } = useServices();
  const navigate = useNavigate();

  const onClickSignOutHandler = () => {
    navigate("/logout");
  };

  const onClickDashboardHandler = () => {
    navigate(routeService.getFirstNavigatedPage);
  };

  const hasPermissions = permissionService.getPermissionData;

  return (
    <Center w="100%" h="100vh" justifyContent="start" p="40px">
      <HStack spacing="20px" ml="40px">
        <UnauthorizeIcon />
        <VStack align="start" spacing="5px">
          <Text fontSize="32px" fontWeight="semibold">
            Oops!
          </Text>
          <Text fontSize="18px" fontWeight="medium">
            You don't have the permission to view this page.
          </Text>
          {hasPermissions ? (
            <Button onClick={onClickDashboardHandler}>Go to Dashboard</Button>
          ) : (
            <Button onClick={onClickSignOutHandler}>Sign Out</Button>
          )}
        </VStack>
      </HStack>
    </Center>
  );
};
