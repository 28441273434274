import { APP_ORIGINS } from "./origins";

export class EnvironmentProfileService {
  private readonly origin = window.origin;

  private readonly app_origins: typeof APP_ORIGINS;

  constructor(appOrigins: typeof APP_ORIGINS) {
    this.app_origins = appOrigins;
  }

  public get getEnvironment() {
    if (this.origin.includes(this.app_origins.local)) return "local";
    if (this.origin.includes(this.app_origins.preRelease)) return "pre-release";
    if (this.origin.includes(this.app_origins.stage)) return "stage";
    return "production";
  }

  public checkEnvironment(
    condition: (typeof this.app_origins)[keyof typeof this.app_origins]
  ) {
    return condition === this.getEnvironment;
  }
}
