import { getUserById } from "crud/user.crud";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useServices } from "services/hooks";
import { TMUser } from "services/userService/models";
import useSWR from "swr";

export const swrMutableOptions = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
};
//for Immutable request
export const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export const useGetUserInfoById = (
  userId: string | null,
  onSuccessEndHandler: (data:TMUser) => void,
  uniqueKey: string | null
) => {
  const userFetcher = () => getUserById(userId).then((res) => res.data);
  const { data, isLoading, error } = useSWR< TMUser >(
    userId ? `users/${userId}/${uniqueKey}` : null,
    userFetcher,
    { ...options, onSuccess: (data) => onSuccessEndHandler(data) }
  );
  return { data, isLoading, error };
};

export const useUserTokenChange = () => {
  const { userService } = useServices();
  const [loginInfo, setLoginInfo] = useState({
    id: null,
    token: null,
  });

  const hasUserToken = useMemo(() => !!loginInfo?.token, [loginInfo?.token]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setUserInfoFn = useCallback(setLoginInfo, []);

  useEffect(() => {
    return userService.initializeLoginListener(setUserInfoFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { hasUserToken };
};
