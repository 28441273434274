import axios, { AxiosInstance, AxiosRequestHeaders } from "axios";
import { NavigateFunction } from "react-router-dom";
import { IAxiosService, IUserService } from "services/models";

export class AxiosService implements IAxiosService {
  private readonly axiosInstance: AxiosInstance;
  private readonly userService: IUserService;
  constructor(userService: IUserService) {
    this.userService = userService;
    this.axiosInstance = axios.create();

    axios.interceptors.request.use(
      (config) => {
        if (userService?.getUserToken) {
          // (config.headers as AxiosRequestHeaders)["Api-Token"] =
          //   userService?.getUserToken;

          (config.headers as AxiosRequestHeaders).Authorization =
            userService?.getUserToken;
        }
        return config;
      },
      (err) => Promise.reject(err)
    );
  }

  public get getAxiosInstance(): AxiosInstance {
    return this.axiosInstance;
  }

  public setUpAxios(navigate: NavigateFunction) {
    axios.interceptors.response.use(
      (response) => {
        if (response.status === 202) {
          navigate("/");
        }
        return response;
      },
      async (error) => {
        if (error.response) {
          if (error.response.status === 403) {
            navigate("/error/no-access");
          }
          if (error.response.status === 401) {
            this.userService.destroy();
            navigate("/error/unauthorize");
          }
        } else {
          if (axios.isCancel(error)) {
            console.log("Request cancelled...");
          } else {
            alert("Server error while fetch data, please try again later");
          }
        }
        return Promise.reject(error);
      }
    );
  }
}
