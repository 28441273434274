import { AdminIcon } from "icons/AdminIcon";
import { DashboardIcon } from "icons/DashboardIcon";
import { IMenuService, IPermissionService } from "services/models";
import { TRoute } from "./models";

export class MenuService implements IMenuService {
  private readonly permissionService: IPermissionService;

  constructor(permissionService: IPermissionService) {
    this.permissionService = permissionService;
  }

  private get getBaseMenu() {
    const slugs = this.permissionService.getPermissionSlugs;
    return [
      {
        id: 1,
        name: "Dashboard",
        url: "/dashboard",
        allowed: this.permissionService.permissionGuaranteed(
          slugs.DASHBOARD_MENU_ACCESS
        ),
        moduleName: slugs.DASHBOARD_MENU_ACCESS,
        icon: DashboardIcon,
      },
      {
        id: 2,
        name: "Shelf Reporting",
        url: "/shelf-reporting",
        allowed: this.permissionService.permissionGuaranteed(
          slugs.SHELF_REPORTING
        ),
        moduleName: slugs.SHELF_REPORTING,
        icon: DashboardIcon,
      },
      {
        id: 3,
        name: "Administration",
        allowed: this.permissionService.permissionGuaranteed(
          slugs.BEAT_PLAN_MANAGEMENT
        ),
        moduleName: slugs.BEAT_PLAN_MANAGEMENT,
        icon: AdminIcon,
        children: [
          {
            id: 1,
            name: "Beat Plan Management",
            icon: AdminIcon,
            url: "/admin/beat-plan-management",
            allowed: this.permissionService.permissionGuaranteed(
              slugs.BEAT_PLAN_MANAGEMENT
            ),
            moduleName: slugs.BEAT_PLAN_MANAGEMENT,
          },
        ],
      },
      {
        id: 4,
        name: "Shelf Reports",
        url: "/shelf/reports",
        allowed: this.permissionService.permissionGuaranteed(
          slugs.SHELF_REPORTS
        ),
        moduleName: slugs.SHELF_REPORTS,
        icon: AdminIcon,
      },
      
    ];
  }

  public get getMenuList(): TRoute[] {
    return this.getBaseMenu.reduce<TRoute[]>((acc, cur) => {
      if (cur.allowed) {
        return [...acc, cur];
      }
      return acc;
    }, []);
  }
}
