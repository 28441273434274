import { Center, Text, Image, VStack, HStack } from "@chakra-ui/react";
import React from "react";
import ProcessingIcon from "./ProcessingIcon";

export const SplashScreen: React.FC = () => {
  return (
    <Center w="100%" h="100%">
      <VStack>
        <Image
          width="90px"
          height="90px"
          aspectRatio="1/2"
          src="/logo512x512.png"
        />
        <HStack spacing="0">
          <ProcessingIcon /> <Text fontWeight="semibold">Loading</Text>
        </HStack>
      </VStack>
    </Center>
  );
};
