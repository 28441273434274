import { Icon } from "@chakra-ui/react";
import React from "react";

export const AdminIcon: React.FC = () => {
  return (
    <Icon
      id="&#x421;&#x43B;&#x43E;&#x439;_1"
      height="20px"
      widths="20px"
      viewBox="0 0 128 128"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      fill="#fff"
    >
      <path
        d="m121.12 96h-1.12v-8.13a14 14 0 0 0 -28 0v8.13h-1.12a6.89 6.89 0 0 0 -6.88 6.88v12.38a12.75 12.75 0 0 0 12.74 12.74h18.52a12.75 12.75 0 0 0 12.74-12.74v-12.38a6.89 6.89 0 0 0 -6.88-6.88zm-25.12-8.13a10 10 0 0 1 20 0v8.13h-20zm28 27.39a8.75 8.75 0 0 1 -8.74 8.74h-18.52a8.75 8.75 0 0 1 -8.74-8.74v-12.38a2.88 2.88 0 0 1 2.88-2.88h30.24a2.88 2.88 0 0 1 2.88 2.88z"
        stroke="#fff"
        strokeWidth="3"
      />
      <path
        d="m106 103.08a5.68 5.68 0 0 0 -2 11v3.92a2 2 0 0 0 4 0v-3.92a5.68 5.68 0 0 0 -2-11zm0 4a1.69 1.69 0 1 1 -1.69 1.69 1.69 1.69 0 0 1 1.69-1.69z"
        stroke="#fff"
        strokeWidth="3"
      />
      <path
        d="m58 67.38a33.5 33.5 0 0 1 27.56 14.49 2 2 0 1 0 3.29-2.27 37.48 37.48 0 0 0 -20.65-14.78 21.62 21.62 0 1 0 -20.42 0 37.53 37.53 0 0 0 -27.03 32.18 54 54 0 1 1 91.25-39 54.59 54.59 0 0 1 -1.1 10.92 2 2 0 1 0 3.91.81 58 58 0 1 0 -35.89 42.38 2 2 0 0 0 -1.44-3.73 54 54 0 0 1 -52.91-8 33.48 33.48 0 0 1 33.43-33zm-17.62-21.62a17.62 17.62 0 1 1 17.62 17.62 17.64 17.64 0 0 1 -17.62-17.62z"
        stroke="#fff"
        strokeWidth="3"
      />
    </Icon>
  );
};
