import { IFeedbackService, IStorageService } from "services/models";

export class FeedbackService implements IFeedbackService {
  private storageService: IStorageService;

  constructor(storageService: IStorageService) {
    this.storageService = storageService;
  }

  public setClosedFeedbackPages(pathName: string) {
    const closedPages = JSON.parse(
      this.storageService.get("closed_feedback_pages") ?? "[]"
    );
    this.storageService.set(
      "closed_feedback_pages",
      JSON.stringify([...closedPages, pathName])
    );
  }

  public shouldShow(pathName: string) {
    const closedPages = JSON.parse(
      this.storageService.get("closed_feedback_pages") ?? "[]"
    ) as string[];
    return !closedPages.includes(pathName);
  }
}
