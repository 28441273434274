import { Box } from "@chakra-ui/react";
import { css, keyframes } from "@emotion/react";
import React from "react";

const animation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const spinnnerContainer = css`
  width: 46px;
  height: 46px;
  display: inline-block;
  overflow: hidden;
  background: none;
`;

const spinnner = css`
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.46);
  backface-visibility: hidden;
  transform-origin: 0 0;
  & div {
    box-sizing: content-box;
    left: 47.5px;
    top: 23px;
    position: absolute;
    animation: ${animation} linear 1s infinite;
    background: #939599;
    width: 5px;
    height: 16px;
    border-radius: 2.5px / 4px;
    transform-origin: 2.5px 27px;
  }
  & div:nth-of-type(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  & div:nth-of-type(2) {
    transform: rotate(45deg);
    animation-delay: -1s;
  }
  & div:nth-of-type(3) {
    transform: rotate(90deg);
    animation-delay: -0.9s;
  }
  & div:nth-of-type(4) {
    transform: rotate(135deg);
    animation-delay: -0.8s;
  }
  & div:nth-of-type(5) {
    transform: rotate(180deg);
    animation-delay: -0.7s;
  }
  & div:nth-of-type(6) {
    transform: rotate(225deg);
    animation-delay: -0.6s;
  }
  & div:nth-of-type(7) {
    transform: rotate(270deg);
    animation-delay: -0.5s;
  }
  & div:nth-of-type(8) {
    transform: rotate(315deg);
    animation-delay: -0.4s;
  }
`;

const ProcessingIcon: React.FC = () => {
  return (
    <Box css={spinnnerContainer}>
      <Box css={spinnner}>
        <Box />
        <Box />
        <Box />
        <Box />
        <Box />
        <Box />
        <Box />
        <Box />
      </Box>
    </Box>
  );
};

export default ProcessingIcon;
