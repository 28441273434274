
import { Button, Center, HStack, Text, VStack } from "@chakra-ui/react";
import { UnauthorizeIcon } from "icons/UnauthorizeIcon";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useServices } from "services/hooks";

export const NotFound: React.FC = () => {
  const { routeService } = useServices();
  const navigate = useNavigate();

  const onClickDashboardHandler = () => {
    navigate(routeService.getFirstNavigatedPage);
  };

  return (
    <Center w="100%" h="100vh" justifyContent="start" p="40px">
      <HStack spacing="20px" ml="40px">
        <UnauthorizeIcon />
        <VStack align="start" spacing="5px">
          <Text fontSize="32px" fontWeight="semibold">
            404!
          </Text>
          <Text fontSize="18px" fontWeight="medium">
            NotFound.
          </Text>
            <Button onClick={onClickDashboardHandler}>Go to Dashboard</Button>
        </VStack>
      </HStack>
    </Center>
  );
};

export default NotFound;