import { Box } from "@chakra-ui/react";
import { SplashScreen } from "components/SplashScreen";
import { useGetUserInfoById, useUserTokenChange } from "hooks";
import React, { useMemo } from "react";
import { Navigate } from "react-router-dom";
import { useServices } from "services/hooks";
import { TMUser } from "services/userService/models";

export const UserWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { userService } = useServices();
  const { hasUserToken } = useUserTokenChange();

  const onSuccessEndHandler = (data: TMUser) => {
    userService.setUserData(data);
  };

  const { data, error, isLoading } = useGetUserInfoById(
    hasUserToken ? userService.getUserId : null,
    onSuccessEndHandler,
    userService?.getUserToken
  );
  const status = useMemo(() => {
    if (error && error?.response?.status === 401) return "authorization-error";
    if (error) return "error";
    if (userService.getUserToken && !data) return "splash";
    return "children";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);
  const renderPage = {
    children: () => <>{children}</>,
    splash: () => <SplashScreen />,
    "authorization-error": () => <Navigate to="/error/unauthorize" />,
    error: () => <Navigate to="/error/not-found" />,
  };


  return (
    <Box w="100%" h="100dvh">
      {renderPage[status]()}
    </Box>
  );
};
