import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import NotFound from "./NotFound";
import { UnauthorizePage } from "./UnauthorizedPage";
import { PermissionError } from "./PermissionError";

const ErrorPages: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/error/not-found" />} />
      <Route path="/no-access" element={<PermissionError />} />
      <Route path="/not-found" element={<NotFound />} />
      <Route path="/unauthorize" element={<UnauthorizePage />} />
    </Routes>
  );
};

export default ErrorPages;
