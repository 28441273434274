import { tabsAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const colorfulVariant = definePartsStyle((props) => {
  return {
    tab: {
      borderBottom: "2px solid",
      borderColor: props.theme.colors.gray[300],
      _selected: {
        color: props.theme.colors.gray[900],
        borderColor: "#FB6D2B",
        fontWeight: "semibold",
      },
    },
  };
});
const searchVariant = definePartsStyle((props) => {
  return {
    tab: {
      color: props.theme.colors.gray[500],
      fontSize: "12px",
      borderBottom: "2px solid white",
      borderColor: "white",
      _selected: {
        color: props.theme.colors.gray[900],
        borderBottom: "2px solid",
        borderColor: "#FB6D2B",
        fontWeight: "semibold",
      },
    },
  };
});

const variants = {
  colorful: colorfulVariant,
  search: searchVariant,
};

// export the component theme
export const tabsTheme = defineMultiStyleConfig({ variants });
