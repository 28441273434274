import { PERMISSION_SLUGS } from "./permissionConfig";

export const PAGE_ACCESS_CHECKLIST = [
  {
    url: /\/dashboard/,
    module: PERMISSION_SLUGS.DASHBOARD_MENU_ACCESS,
  },
  {
    url: /\/shelf-reporting/,
    module: PERMISSION_SLUGS.DASHBOARD_MENU_ACCESS,
  },
  // {
  //   url: /\/shelf-reports/,
  //   module: PERMISSION_SLUGS.SHELF_REPORTS,
  // },
  {
    url: /\/shelf\/reports(?:\/.*)?/,
    module: PERMISSION_SLUGS.SHELF_REPORTS,
  },
  {
    url: /\/shelf-reporting/,
    module: PERMISSION_SLUGS.SHELF_REPORTING,
  },
  {
    url: /\/admin\/beat-plan-management(?:\/.*)?/,
    module: PERMISSION_SLUGS.BEAT_PLAN_MANAGEMENT,
  },
];
