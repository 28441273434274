import React from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useServices } from "services/hooks";

export const AuthProtectedRoute: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { userService } = useServices();
  const [searchParams] = useSearchParams();
  const companyToken = searchParams.get("___Y29tcGFueVRva2VuX2Zyb21fY2xpZW50") || ""; //base 64 of companyToken_from_client https://www.base64encode.org/
  const userToken = searchParams.get("___dXNlclRva2VuX2Zyb21fY2xpZW50") || ""; //base 64 of userToken_from_client https://www.base64encode.org/
  if ((userService.getUserData || userService.getUserToken) && !(companyToken || userToken))
    return <Navigate to="/" />;
  return <>{children}</>;
};
