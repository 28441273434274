import { Button, Center, HStack, Text, VStack } from "@chakra-ui/react";
import { UnauthorizeIcon } from "icons/UnauthorizeIcon";
import React from "react";
import { useNavigate } from "react-router-dom";

export const UnauthorizePage: React.FC = () => {
  const navigate = useNavigate();
  const onClickSignOutHandler = () => {
    navigate("/logout");
  };

  return (
    <Center w="100%" h="100vh" justifyContent="start" p="40px">
      <HStack spacing="20px" ml="40px">
        <UnauthorizeIcon />
        <VStack align="start" spacing="5px">
          <Text fontSize="32px" fontWeight="semibold">
            Unauthorized Access
          </Text>
          <Text fontSize="18px" fontWeight="medium">
            Please log in or check your credentials and try again.
          </Text>
          <Button onClick={onClickSignOutHandler}>Sign Out</Button>
        </VStack>
      </HStack>
    </Center>
  );
};
