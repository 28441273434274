import { IStorageService } from "services/models";

export class StorageService implements IStorageService {
  public set(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  public remove(key: string): void {
    localStorage.removeItem(key);
  }

  public get(key: string): string | null {
    return localStorage.getItem(key);
  }
}
