import axios from "axios";
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const getUserById = (id) => {
  return axios.get(`${REACT_APP_API_URL}api/users/${id}`, {
    transformRequest: (data, headers) => {
      delete headers["Api-Token"];
      headers["Authorization"] = localStorage.getItem("auth_token");
      return data;
    },
  });
};
