import React from "react";
import { IServices } from "./models";

export const ServicesContext = React.createContext<IServices>({
  userService: null as any,
  permissionService: null as any,
  storageService: null as any,
  menuService: null as any,
  routeService: null as any,
  axiosService: null as any,
  environmentProfileService: null as any,
  feedbackService: null as any,
});

export const ServiceProvider = React.memo(
  ({ context, children }: React.PropsWithChildren<{ context: IServices }>) => {
    return (
      <ServicesContext.Provider value={context}>
        {children}
      </ServicesContext.Provider>
    );
  }
);
