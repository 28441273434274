import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const colorfulVariant = definePartsStyle((props) => {
  return {
    container: {
      border: "none",
      mb: "20px",
    },
    button: {
      color: "white",
      fontSize: "16px",
      bg: "#FB6D2B !important",
      borderRadius: "5px",
    },
    panel: {
      marginTop: "10px",
      padding: "0",
    },
  };
});

const searchVariant = definePartsStyle((props) => {
  return {
    container: {
      border: "none",
      mb: "20px",
      height: "auto",
    },
    button: {
      color: "black",
      fontSize: "16px",
      bg: "white !important",
      borderRadius: "10px",
      height: "40px",
    },
    panel: {
      padding: "0",
      bg: "white",
      marginTop: "0",
      borderBottomRadius: "10px",
    },
  };
});
const breakdownVariant = definePartsStyle((props) => {
  return {
    container: {
      border: "1px solid",
      borderColor: `#e2e8f0 !important`,
      height: "auto",
      borderRadius: "4px",
      my: "10px",
    },
    button: {
      color: "black",
      fontSize: "13px",
      bg: "white !important",
      borderRadius: "10px",
      height: "40px",
    },
    panel: {
      bg: "white",
      marginTop: "0",
      borderBottomRadius: "10px",
      p: 0,
    },
  };
});

const variants = {
  colorful: colorfulVariant,
  search: searchVariant,
  breakdown: breakdownVariant,
};

// export the component theme
export const accordionTheme = defineMultiStyleConfig({ variants });
