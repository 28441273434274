import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const solidStyle = defineStyle((props) => {
  return {
    color: props.theme.colors.white,
    bg: "rgb(255, 106, 21) !important",
    fontSize: "14px",
    fontWeight: "semibold",

    borderRadius: "4px",

    _hover: {
      opacity: 0.8,
    },
  };
});

const sidebarStyle = defineStyle((props) => {
  return {
    color: props.theme.colors.white,
    bg: "transparent",
    fontSize: "14px",
    fontWeight: "semibold",

    borderRadius: "4px",

    _active: {
      bg: "#FB6D2B",
    },

    _hover: {
      bg: "#FB6D2B",
    },
  };
});

const outlineStyle = defineStyle((props) => {
  return {
    color: props.theme.colors.white,
    fontSize: "14px",
    fontWeight: "semibold",
    borderRadius: "4px",
    borderColor: "rgb(255, 106, 21)",

    _hover: {
      opacity: 0.8,
    },
    _loading: {
      bg: "rgb(255, 106, 21)",
      opacity: 0.8,
      _hover: { bg: "rgb(255, 106, 21) !important" },
    },
  };
});

const linkStyle = defineStyle((props) => {
  return {
    color: props.theme.colors.white,
    fontSize: "14px",
    height: "30px",
    borderRadius: "4px",
    borderColor: "rgb(255, 106, 21)",
    bg: "rgb(33, 63, 82) !important",

    _hover: {
      opacity: 0.8,
    },
    _loading: {
      bg: "rgb(255, 106, 21)",
      opacity: 0.8,
      _hover: { bg: "rgb(255, 106, 21) !important" },
    },
  };
});

const mapMoreStyle = defineStyle((props) => {
  return {
    color: props.theme.colors.gray[500],
    fontSize: "14px",
    fontWeight: "semibold",
    height: "auto",
    pt: "5px",
    borderRadius: "4px",
    px: 0,

    _after: {
      content: `""`,
      position: "absolute",
      width: "100%",
      transform: "scaleX(0)",
      height: "2px",
      bottom: 0,
      left: 0,
      backgroundColor: "#FB6D2B",
      transformOrigin: "bottom right",
      transition: "transform 0.25s ease-out",
    },

    _hover: {
      color: "#FB6D2B",
      _after: {
        transform: "scaleX(1)",
        transformOrigin: "bottom left",
      },
    },
  };
});

const signOutStyle = defineStyle((props) => {
  return {
    color: "black",
    bg: "rgb(217, 217, 217) !important",
    _hover: { color: "white", bg: "#1c3545 !important" },
  };
});

const variants = {
  solid: solidStyle,
  sidebar: sidebarStyle,
  outline: outlineStyle,
  link: linkStyle,
  mapMore: mapMoreStyle,
  signOut: signOutStyle,
};

// export the component theme
export const buttonTheme = defineStyleConfig({ variants });
