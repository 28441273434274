// import { ChakraProvider } from "@chakra-ui/react";
// import { UserWrapper } from "UserWrapper";
// import React from "react";
// import { createRoot } from "react-dom/client";
// import { BrowserRouter } from "react-router-dom";
// import { services } from "services";
// import { ServiceProvider } from "services/ServiceProvider";
// import theme from "styles/theme";
// import "../src/assets/flaticon/flaticon.css";
// import "../src/assets/flaticon2/flaticon.css";
// import "../src/assets/pm-icons/iconmoon.css";
// import App from "./App";

// const container = document.getElementById("merchant-eye") as HTMLElement;
// const root = createRoot(container);

// root.render(
//   <React.StrictMode>
//     <ChakraProvider theme={theme}>
//       <BrowserRouter>
//         <ServiceProvider context={services}>
//           <UserWrapper>
//             <App />
//           </UserWrapper>
//         </ServiceProvider>
//       </BrowserRouter>
//     </ChakraProvider>
//   </React.StrictMode>
// );
import { ChakraProvider } from "@chakra-ui/react";
import { UserWrapper } from "UserWrapper";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { services } from "services";
import { ServiceProvider } from "services/ServiceProvider";
import theme from "styles/theme";
import "../src/assets/flaticon/flaticon.css";
import "../src/assets/flaticon2/flaticon.css";
import "../src/assets/pm-icons/iconmoon.css";
import App from "./App";
import ErrorPages from "pages/errors/ErrorsPage";
import { SplashScreen } from "components/SplashScreen";

const container = document.getElementById("merchant-eye") as HTMLElement;
const root = createRoot(container);

const LogoutPage = React.lazy(() => import("pages/auth/Logout"));

root.render(
  // <React.StrictMode>
  <ChakraProvider theme={theme}>
    <BrowserRouter>
      <ServiceProvider context={services}>
        <Routes>
          <Route path="/error/*" element={<ErrorPages />} />
          <Route
            path="/logout"
            element={
              <React.Suspense fallback={<SplashScreen />}>
                <LogoutPage />
              </React.Suspense>
            }
          />
          <Route
            path="/*"
            element={
              <UserWrapper>
                <App />
              </UserWrapper>
            }
          />
        </Routes>
      </ServiceProvider>
    </BrowserRouter>
  </ChakraProvider>
  // </React.StrictMode>
);

