import { AuthProtectedRoute } from "AuthProtectedRoute";
import { SplashScreen } from "components/SplashScreen";
import React from "react";
import { Route, Routes } from "react-router-dom";

const AuthPageRoutes = React.lazy(() => import("pages/auth/AuthPage"));
const AppRoutesPage = React.lazy(() => import("AppRoutes"));

export const MainRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/auth/*"
        element={
          <AuthProtectedRoute>
            <React.Suspense fallback={<SplashScreen />}>
              <AuthPageRoutes />
            </React.Suspense>
          </AuthProtectedRoute>
        }
      />

      <Route
        path="/*"
        element={
          <React.Suspense fallback={<SplashScreen />}>
            <AppRoutesPage />
          </React.Suspense>
        }
      />
    </Routes>
  );
};
