import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const colorfulVariant = definePartsStyle((props) => {
  return {
    control: {
      display: "none",
    },
    label: {
      fontSize: "14px",
      color: "#C4C4C4",
      fontWeight: "bold",
      margin: 0,
      _checked: {
        color: "white",
      },
    },
    container: {
      w: "120px",
      h: "40px",
      border: "1px solid #C4C4C4",
      borderRadius: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      _checked: {
        border: "none",
        backgroundColor: "#FB6D2B",
      },
    },
  };
});

const filteredVariant = definePartsStyle((props) => {
  return {
    control: {
      display: "none",
    },
    label: {
      fontSize: { base: "6px", md: "12px" },
      fontWeight: "bold",
      color: "#000000",

      _checked: {
        color: "white",
        bg: "#F26B23",
      },
    },
    container: {
      borderRadius: "5px",
      m: "0",
      px: { base: "10px", md: "20px" },
      py: { base: "6px", md: "12px" },
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      _checked: {
        border: "none",
        backgroundColor: "#FB6D2B",
      },
    },
  };
});

const variants = {
  colorful: colorfulVariant,
  filter: filteredVariant,
};

// export the component theme
export const radioTheme = defineMultiStyleConfig({ variants });
