import { Icon } from "@chakra-ui/react";
import React from "react";

export const DashboardIcon: React.FC = () => {
  return (
    <Icon
      enableBackground="new 0 0 32 32"
      fill="#fff"
      height="20px"
      viewBox="0 0 32 32"
      width="20px"
    >
      <g id="Layer_1">
        <g>
          <path
            d="m27.531 30h-8.062c-1.361 0-2.469-1.108-2.469-2.469v-10.062c0-1.361 1.108-2.469 2.469-2.469h8.062c1.361 0 2.469 1.108 2.469 2.469v10.062c0 1.361-1.108 2.469-2.469 2.469zm-8.062-13c-.259 0-.469.21-.469.469v10.062c0 .259.21.469.469.469h8.062c.259 0 .469-.21.469-.469v-10.062c0-.259-.21-.469-.469-.469z"
            fill="#fff"
          />
        </g>
        <g>
          <path
            d="m27.531 13h-8.062c-1.361 0-2.469-1.108-2.469-2.469v-6.062c0-1.361 1.108-2.469 2.469-2.469h8.062c1.361 0 2.469 1.108 2.469 2.469v6.062c0 1.361-1.108 2.469-2.469 2.469zm-8.062-9c-.259 0-.469.21-.469.469v6.062c0 .259.21.469.469.469h8.062c.259 0 .469-.21.469-.469v-6.062c0-.259-.21-.469-.469-.469z"
            fill="#fff"
          />
        </g>
        <g>
          <path
            d="m12.531 17h-8.062c-1.361 0-2.469-1.108-2.469-2.469v-10.062c0-1.361 1.108-2.469 2.469-2.469h8.062c1.361 0 2.469 1.108 2.469 2.469v10.062c0 1.361-1.108 2.469-2.469 2.469zm-8.062-13c-.259 0-.469.21-.469.469v10.062c0 .259.21.469.469.469h8.062c.259 0 .469-.21.469-.469v-10.062c0-.259-.21-.469-.469-.469z"
            fill="#fff"
          />
        </g>
        <g>
          <path
            d="m12.531 30h-8.062c-1.361 0-2.469-1.108-2.469-2.469v-6.062c0-1.361 1.108-2.469 2.469-2.469h8.062c1.361 0 2.469 1.108 2.469 2.469v6.062c0 1.361-1.108 2.469-2.469 2.469zm-8.062-9c-.259 0-.469.21-.469.469v6.062c0 .259.21.469.469.469h8.062c.259 0 .469-.21.469-.469v-6.062c0-.259-.21-.469-.469-.469z"
            fill="#fff"
          />
        </g>
      </g>
    </Icon>
  );
};
