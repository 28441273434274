import { tableAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys);

const strippedTableVariant = definePartsStyle((props) => {
  return {
    table: {
      bg: "transparent",
      overflow: "hidden",
    },
    thead: {
      bg: props.theme.colors.blackAlpha[200],
      borderRadius: "2px",
      th: {
        fontWeight: 900,
        fontSize: "12px",
        color: props.theme.colors.gray[600],
        paddingTop: "10px",
        paddingBottom: "10px",
        textTransform: "capitalize",
      },
    },
    tbody: {
      tr: {
        fontSize: "13px",
        _even: {
          bg: props.theme.colors.blackAlpha[200],
        },
      },
    },
  };
});

const serviceChargeTableVariant = definePartsStyle((props) => {
  return {
    table: {
      bg: "transparent",
      overflow: "hidden",
    },
    thead: {
      bg: "#f4f5f8",
      borderRadius: "2px",

      tr: {
        th: {
          fontWeight: 600,
          width: "20%",
          fontSize: "11px",
          color: "black",
          paddingTop: "16px",
          paddingBottom: "16px",
          textTransform: "capitalize",
          textAlign: "center",
        },
        _first: {
          th: {
            _first: {
              textAlign: "start",
              width: "60%",
              paddingLeft: "30px",
            },
          },
        },
        _last: {
          th: {
            _last: {
              width: "15%",
            },
          },
        },
      },
    },
    tbody: {
      tr: {
        borderBottom: "1px solid #e0e0e0",
        fontWeight: 600,
        width: "20%",
        fontSize: "13px",
        color: "black",

        textTransform: "capitalize",
        td: {
          paddingTop: "10px",
          paddingBottom: "10px",
          fontWeight: 400,
          textAlign: "center",
          _first: {
            textAlign: "center",
            paddingLeft: "30px",
          },
        },
      },
    },
  };
});

const sortedTableVariant = definePartsStyle((props) => {
  return {
    table: {
      bg: "transparent",
      overflow: "hidden",
    },
    thead: {
      borderRadius: "2px",
      tr: {
        th: {
          fontWeight: 600,
          fontSize: "14px",
          color: props.theme.colors.gray[600],
          height: "48px",
          lineHeight: "48px",
          textTransform: "capitalize",
          borderTop: "1px solid #E0E0E0",
          borderBottom: "1px solid #E0E0E0",
          boxSizing: "border-box",
          _last: {
            borderRight: "1px solid #E0E0E0",
            boxSizing: "border-box",
          },
        },
        _first: {
          th: {
            _first: {
              borderTop: "none",
              borderRight: "1px solid #E0E0E0",
              boxSizing: "border-box",
            },
          },
        },
        _even: {
          th: {
            color: "#838383",
            textAlign: "center",
            _first: {
              color: "#000000",
              textAlign: "start",
              borderRight: "1px solid #E0E0E0",
              borderLeft: "1px solid #E0E0E0",
              boxSizing: "border-box",
            },
          },
        },
      },
    },
    tbody: {
      tr: {
        fontSize: "14px",
        borderTop: "1px solid #E0E0E0",
        borderBottom: "1px solid #E0E0E0",
        boxSizing: "border-box",
        _even: {
          bg: "#F5F5F5",
        },

        td: {
          color: "#212121",
          paddingTop: "16px",
          paddingBottom: "16px",
          textAlign: "center",
          _first: {
            borderRight: "1px solid #E0E0E0",
            borderLeft: "1px solid #E0E0E0",
            textAlign: "start",
            boxSizing: "border-box",
          },
          _last: {
            borderRight: "1px solid #E0E0E0",
            boxSizing: "border-box",
          },
        },
      },
    },
  };
});

const campaignTableVariant = definePartsStyle((props) => {
  return {
    thead: {
      tr: {
        borderBottom: "1px solid",
        borderColor: "rgb(226, 232, 240)",
      },
      th: {
        textTransform: "capitalize",
        color: "black",
        fontSize: "12px",
      },
    },
    tbody: {
      tr: { borderBottom: "1px solid", borderColor: "rgb(226, 232, 240)" },
      td: { fontSize: "12px" },
      _last: { tr: { border: "none" } },
    },
  };
});

const brokerTableVariant = definePartsStyle((props) => {
  return {
    thead: {
      tr: {
        borderBottom: "1px solid",
        borderColor: "rgb(226, 232, 240)",
      },
      th: {
        textTransform: "capitalize",
        color: "black",
        fontSize: "12px",
        padding: "15px",
      },
    },
    tbody: {
      tr: {
        borderBottom: "1px solid rgb(226, 232, 240) !important",
        _last: { border: "none !important" },
      },
      td: {
        fontSize: "12px",
        padding: "20px 15px",
      },
    },
  };
});

const breakdownTableVariant = definePartsStyle((props) => {
  return {
    thead: {
      tr: {
        borderBottom: "1px solid",
        borderColor: "rgb(226, 232, 240)",
        backgroundColor: props.theme.colors.gray[300],
      },
      th: {
        textTransform: "capitalize",
        color: "black",
        fontSize: "10px",
        px: "15px",
        _last: {
          textAlign: "end",
        },
      },
    },
    tbody: {
      tr: {
        borderBottom: "1px solid rgb(226, 232, 240) !important",
        _last: { border: "none !important" },
      },
      td: {
        fontSize: "12px",
        py: "8px",
        px: "15px",
        _last: {
          textAlign: "end",
        },
      },
    },
  };
});

const variants = {
  strippedTable: strippedTableVariant,
  sorted: sortedTableVariant,
  campaign: campaignTableVariant,
  broker: brokerTableVariant,
  serviceCharge: serviceChargeTableVariant,
  breakdown: breakdownTableVariant,
};

// export the component theme
export const tableTheme = defineMultiStyleConfig({ variants });
