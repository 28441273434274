import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const colorfulVariant = definePartsStyle(() => {
  return {
    control: {
      boxSize: 24,
      borderRadius: "2px",
      height: "16px",
      width: "16px",
      _checked: {
        bg: "#2B3E4A !important",
        borderColor: "#2B3E4A !important",
      },
      _disabled: {
        opacity: 0.8,
      },
    },
    label: {
      fontSize: { base: "8px", md: "14px" },
    },
  };
});
const legendVariant = definePartsStyle((props) => {
  return {
    control: {
      bg: `white`,
      boxSize: 24,
      borderRadius: "1px",
      height: { base: "14px", md: "18px" },
      width: { base: "14px", md: "18px" },
      _checked: {
        opacity: 1,
        bg: `${props.colorScheme} !important`,
        border: "none",
        borderRadius: "3px",
      },
    },
    label: {
      fontSize: { base: "8px", md: "14px" },
      _checked: { opacity: 1 },
    },
  };
});

const variants = {
  colorful: colorfulVariant,
  legend: legendVariant,
};

// export the component theme
export const checkboxTheme = defineMultiStyleConfig({ variants });
