import { MainRoutes } from "MainRoutes";
import React from "react";
import { SWRConfig } from "swr";
// import { YMInitializer } from "@appigram/react-yandex-metrika";

const App: React.FC = () => {
  // const REACT_APP_METRICA_TRACK = process.env?.REACT_APP_METRICA_TRACK;
  // const REACT_APP_METRICA_TAG_ID = Number(
  //   String(process.env.REACT_APP_METRICA_TAG_ID)
  // );
  return (
    <React.Suspense>
      <SWRConfig
        value={{
          dedupingInterval: 10 * 60 * 1000,
          revalidateIfStale: false,
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
        }}
      >
        {/* {REACT_APP_METRICA_TRACK === "ON" ? (
          <YMInitializer
            accounts={[REACT_APP_METRICA_TAG_ID]}
            options={{
              clickmap: true,
              // defer: true,
              trackLinks: true,
              accurateTrackBounce: true,
              webvisor: true
            }}
          />
         ) : null} */}
        <MainRoutes />
      </SWRConfig>
    </React.Suspense>
  );
};

export default App;
