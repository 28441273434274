import { IPermissionService, IUserService } from "services/models";
import { PERMISSION_SLUGS } from "./permissionConfig";
import { PAGE_ACCESS_CHECKLIST } from "./pageAccessCheckListConfig";

export class PermissionService implements IPermissionService {
  private readonly userService: IUserService;
  private permissionSlugs: typeof PERMISSION_SLUGS;
  private urlPermissionLists: typeof PAGE_ACCESS_CHECKLIST;

  constructor(
    userService: IUserService,
    permissionSlugs: typeof PERMISSION_SLUGS,
    urlPermissionLists: typeof PAGE_ACCESS_CHECKLIST
  ) {
    this.permissionSlugs = permissionSlugs;
    this.userService = userService;
    this.urlPermissionLists = urlPermissionLists;
  }

  public get getPermissionSlugs() {
    return this.permissionSlugs;
  }

  public get getPermissionData() {
    return this.userService.getUserData?.permissions;
  }

  public permissionGuaranteed(moduleName: string | string[]) {
    if (!this.userService.getUserData) return false;
    if (typeof moduleName === "string") {
      return !!this?.userService.getUserData.permissions?.find((permission) => {
        return permission === moduleName;
      });
    }

    return moduleName?.reduce((acc, curr) => {
      const module = !!this?.userService.getUserData.permissions?.find(
        (permission) => {
          return permission === curr;
        }
      );
      if (module) {
        return true;
      }
      return acc;
    }, false);
  }

  public get getUrlPermissionLists() {
    return this.urlPermissionLists;
  }
}
