const styles = {
  global: (props) => ({
    body: {
      fontFamily: "Roboto",
      transitionProperty: "background-color",
      transitionDuration: "normal",
      lineHeight: "base",
    },
  }),
};

export default styles;
