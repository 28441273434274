import { extendTheme } from "@chakra-ui/react";
import { tabsTheme } from "./components/Tabs";
import { radioTheme } from "./components/Radio";
import { tableTheme } from "./components/Table";
import { accordionTheme } from "./components/Accordion";
import { buttonTheme } from "./components/Button";
import styles from "./styles";
import { checkboxTheme } from "./components/Checkbox";

const theme = extendTheme({
  styles,
  components: {
    Tabs: tabsTheme,
    Radio: radioTheme,
    Table: tableTheme,
    Accordion: accordionTheme,
    Button: buttonTheme,
    Checkbox: checkboxTheme,
  },
  breakpoints: {
    desktopViewBP: '90em',
    tabletViewBP: '74.938em',
    landscapeBP: 'landscape'
  },
});

export default theme;
