import { AxiosService } from "./axios";
import { EnvironmentProfileService } from "./environmentProfileService";
import { APP_ORIGINS } from "./environmentProfileService/origins";
import { FeedbackService } from "./feedback";
import { MenuService } from "./menuService";
import { IServices } from "./models";
import { PermissionService } from "./permissionService";
import { PAGE_ACCESS_CHECKLIST } from "./permissionService/pageAccessCheckListConfig";
import { PERMISSION_SLUGS } from "./permissionService/permissionConfig";
import { RouteService } from "./routeService";
import { StorageService } from "./storageService";
import { UserService } from "./userService";

export const buildService = (): IServices => {
  const _storageService = new StorageService();
  const _userService = new UserService(_storageService);
  const _permissionService = new PermissionService(
    _userService,
    PERMISSION_SLUGS,
    PAGE_ACCESS_CHECKLIST
  );
  const _menuService = new MenuService(_permissionService);
  const _routeService = new RouteService(
    _permissionService,
    _storageService,
    _menuService
  );

  const _axiosService = new AxiosService(_userService);

  const _environmentProfileService = new EnvironmentProfileService(APP_ORIGINS);

  const _feedbackService = new FeedbackService(_storageService);

  return {
    userService: _userService,
    permissionService: _permissionService,
    storageService: _storageService,
    menuService: _menuService,
    routeService: _routeService,
    axiosService: _axiosService,
    environmentProfileService: _environmentProfileService,
    feedbackService: _feedbackService,
  };
};

export const services = buildService();
