import { Icon } from "@chakra-ui/react";
import React from "react";

export const UnauthorizeIcon: React.FC = () => {
  return (
    <Icon width="210px" height="210px" viewBox="0 0 394 345" fill="none">
      <path
        opacity="0.5"
        d="M388 41.5866H6.00566V32.2123C6.00566 18.292 17.3173 7 31.2815 7H362.718C376.677 7 388 18.292 388 32.2123V41.5866ZM388 254.419V263.793C388 277.714 376.688 289 362.724 289H31.2815C17.323 289 6 277.714 6 263.788V254.419H388Z"
        fill="#203440"
      />
      <path
        d="M361.923 0H32.0829C14.3923 0 0 14.4303 0 32.1619V63.3588C0 67.1907 3.09571 70.2946 6.92314 70.2946C10.7393 70.2946 13.835 67.1907 13.835 63.3532V32.1676C13.835 22.0828 22.019 13.8828 32.0716 13.8828H361.923C371.981 13.8828 380.159 22.0828 380.159 32.1676V238.175C380.159 242.007 383.255 245.117 387.083 245.117C390.904 245.117 394 242.007 394 238.175V32.1619C394 14.4303 379.608 0 361.923 0ZM387.077 256.923C383.261 256.923 380.165 260.027 380.165 263.864C380.165 273.944 371.981 282.149 361.928 282.149H32.0716C22.0133 282.149 13.835 273.944 13.835 263.864V90.1821C13.835 86.3502 10.7393 83.2406 6.91189 83.2406C3.09571 83.2406 0 86.3445 0 90.1821V263.859C0 281.596 14.3923 296.026 32.0772 296.026H164.309L155.906 331.128H123.204C119.376 331.128 116.281 334.232 116.281 338.064C116.281 341.89 119.376 345 123.204 345H270.791C274.618 345 277.714 341.896 277.714 338.059C277.714 334.232 274.618 331.123 270.791 331.123H238.089L229.679 296.021H361.917C379.602 296.021 393.994 281.596 393.994 263.859C393.994 260.027 390.899 256.923 387.071 256.923H387.077ZM223.865 331.123H170.146L178.55 296.021H215.462L223.865 331.123Z"
        fill="#203440"
      />
      <path d="M202.503 80L125 211H280L202.503 80Z" fill="#203440" />
    </Icon>
  );
};
